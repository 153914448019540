import { createContext, Dispatch, SetStateAction } from "react";
export interface IDataScroll {
  isBlockScroll: boolean;
  countSlides: number;
  scrollSlide: number;
}
interface IDataGlobalContext {
  slide: number;
  isBlock: boolean;
  needsUpdate: number;
}

export type TChangeGlobalContext = <
  K extends keyof IDataGlobalContext,
  D extends IDataGlobalContext[K]
>(
  key: K,
  data: D
) => void;

interface IGlobalContext {
  data: IDataGlobalContext;
  setData: Dispatch<SetStateAction<IDataGlobalContext>>;
  onChangeData: TChangeGlobalContext;
}

export const initDataGlobalContext: IDataGlobalContext = {
  slide: 1,
  isBlock: true,
  needsUpdate: 0,
};

export const GlobalContext = createContext<IGlobalContext>({
  data: initDataGlobalContext,
  onChangeData: () => {},
  setData: () => {},
});
